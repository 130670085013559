import classes from './Wizard.module.css';
import { useState, useEffect } from "react";
import { ProgressBar, Typography, Button } from "../../components";
import { nextAPI } from "../../api/axiosAPI"
import { useRouter } from 'next/router';
import WestIcon from '@mui/icons-material/West';
import Spinner from '@components/Spinner';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { 
  TICKETS_WIZARD_CAT,
  WIZARD_TRANSLATION_NAMESPACE,
  WIZARD_PREV_URL
} from "@constants";
import { DticketingDialog } from '@components/dticketingDialog';
import { useDticketingTranslate } from 'src/hooks/useDticketingTranslate';

const Wizard = (props) => {
  const {
    currentStep,
    description,
    descriptionSupplement,
    showProgressBar,
    steps,
    title,
    backUrl,
    nextCallback,
    nextUrl,
    supplementaryButtonText,
    supplementaryButtonAction,
    modal
  } = props
  const { t } = useDticketingTranslate(WIZARD_TRANSLATION_NAMESPACE, false);
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] =  useState(modal);
  const [backToHomePage, setBackToHomePage] =  useState(false);


  const onNextClick = () => {
    if (nextCallback) {
      nextCallback()
    } else if (nextUrl) {
      router.push(nextUrl, null, { shallow: true })
    }
  }


  useEffect(() => {
    const handleWizardAccessBlock = async () => {
      setLoading(true);
      const category = sessionStorage.getItem(TICKETS_WIZARD_CAT)
      const customer = JSON.parse(localStorage.getItem("customer"))

      const body = {
          customerId: customer.id,
          size: 1,
          sort_order: "desc",
          sort_by: "created_at",
          locale: "it",
      };

      const { data } = await nextAPI.post("/upload", body);
      if (data.length > 0) {
          const lastUpload = data[0];
        if (lastUpload.status.toLowerCase() === "processing") {
            router.push({
                pathname: category === "g" ? "/" : "/op_management",
                query: {
                    mode: "wizardBlock"
                }
            });
          }
      }
      setLoading(false);
    }
    handleWizardAccessBlock()
  }, [])

  const handlershowModal = (homepageClicked = false) => {
    setOpenModal(!openModal);
    setBackToHomePage(homepageClicked);
  }
  
  const handlerGoToOrigin = (targetUrl = null) => {
    setLoading(true);
    if(sessionStorage.getItem(WIZARD_PREV_URL)) {
      sessionStorage.removeItem(WIZARD_PREV_URL);
    }
    if (typeof targetUrl === 'string' && targetUrl !== '/null' && !backToHomePage) {
      router.push(targetUrl, null, { shallow: true });
    } else {
      router.push('/', null, { shallow: true });
    }
  };
  
  const handlerGoBack = () => {
    if(currentStep == 1) {
     setOpenModal(true);
     return;
    }
    router.push(backUrl, null, { shallow: true });
  }
  return (
    <>
      {currentStep!==5 && loading && <Spinner />}
      <DticketingDialog title={t("dialog.title")}  onClickNegativeAction={handlershowModal} onClickPositiveAction={()=> handlerGoToOrigin(sessionStorage.getItem(WIZARD_PREV_URL))} negativeLabel={t("dialog.cancel")} positiveLabel={t('dialog.exit')} open={openModal} onClickIconAction={handlershowModal} >
      {t("dialog.body")}
      </DticketingDialog>
      <div className={classes.wizardContainer}>
        <div className={classes.header}>
          <a 
            className="text-app-defaultColor flex items-center cursor-pointer hover:opacity-80 w-fit"
            onClick={() => handlershowModal(true)}
          >
            <WestIcon />
            <span
              className="ml-2"
            >{t(`home`)}</span>
          </a>
        </div>

        <div className={`${classes.bodyContainer} flex flex-col`}>
          <h2 className="text-3xl block mb-11  mt-11 font-semibold">
            {title}
          </h2>
          { showProgressBar &&
            <ProgressBar steps={steps} currentStep={currentStep}/>
          }
          <div className='mb-2 mt-11'>
            <h2 className="text-xl font-bold inline">
              {description}
            </h2>
            {descriptionSupplement && (
              <h2 className='underline text-xl font-bold inline'>
                {"\t"}{descriptionSupplement}
              </h2>
            )}
          </div>


          { props.children }
        </div>
        <div className={`${classes.footer} flex flex-row`}>
          <Button
            variant="secondary"
            css="mx-0 h-[36px] leading-[36px] text-center"
            onClick={handlerGoBack}
          >
            {t(`back`)}
          </Button>

          {supplementaryButtonText && (
            <Button
              onClick={supplementaryButtonAction}
              variant={supplementaryButtonAction ? "primary" : "disabled-btn"}
              disabled={!supplementaryButtonAction}
              css={`ml-auto mr-2 h-[36px] eading-[36px] text-center ${supplementaryButtonAction ? "hover:bg-app-defaultColor hover:text-white transition-all" : "cursor-not-allowed"}`}
              endIcon={<LockOpenIcon />}
            >
              {supplementaryButtonText}
            </Button>
          )}
          

          <Button
            // TODO _CR_: assegnate dei data-testid statici a tutti gli elementi con cui interagisce l'utente, così da poterci scrivere dei test in futuro
            data-testid="wizard-next-btn"
            css="mx-0 h-[36px] leading-[36px] text-center"
            disabled={!nextUrl && !nextCallback} // TODO: tickets wizard - remove !nextCallback
            variant={nextUrl || nextCallback ? "primary" : "disabled-btn"}
            onClick={onNextClick}
          >
            {currentStep && currentStep < steps.length ? t('next') : t('send')}
          </Button>
        </div>
      </div>
    </>
  )
}

Wizard.defaultProps = {
  showProgressBar: true
}

export default Wizard;